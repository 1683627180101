import React from "react"
import GuidesIndex from "../components/guides-index"
import Signup from "../components/social/signup"
import { graphql } from "gatsby"
import Columns from "../components/layout/columns"
import Column from "../components/layout/column"
import { Section } from "../components/sections/section"
import TileAncestor from "../components/layout/tile-ancestor"
import TileChild from "../components/layout/tile-child"
import TileParent from "../components/layout/tile-parent"
import { SocialIcons } from "../components/social/social-icons"
import Seo from "../components/seo"
import Layout from "../components/layout/layout"
import { StaticImage } from "gatsby-plugin-image"

function HomepageMargins(props) {
  return (
    <div style={{ paddingLeft: "2em", paddingRight: "2em", maxWidth: "30em" }}>
      {props.children}
    </div>
  )
}

const Index = ({ data }) => {
  return (
    <Layout backgroundStyle="white">
      <Seo thumbnail_name={"index-thumbnail.png"} />
      <div className="section mt-3">
        <Columns>
          <Column>
            <StaticImage
              src="../images/index/derek_hill_profile.jpg"
              alt="Derek Hill profile picture"
            />
          </Column>
          <Column>
            <HomepageMargins>
              <br />
              <br className="is-hidden-mobile" />
              <h1 className="title is-size-3">Hello, I’m Derek Hill</h1>
              <p className="is-size-5">
                I’m deeply curious about how marketing works.
              </p>
              <br />
              <p className="is-size-5">
                I learned to code, built and sold a business, and now do
                research and consulting.
              </p>
              <br />
              <p className="is-size-5">
                My passion is seeing through the fog of digital, to understand
                what matters most in any situation.
              </p>
              <br />
              <p className="is-size-5">
                I collaborate with people across the industry to create 1-page
                guides to marketing and marketing technology.
              </p>
              <br />
              <p className="is-size-5">
                If you have questions, or ideas for topics, please don't
                hesitate to get in touch.
              </p>
              <br />
              <SocialIcons iconColor="platform" />
            </HomepageMargins>
          </Column>
        </Columns>
        <TileAncestor>
          <TileParent classes={["is-vertical"]}>
            <TileChild>
              <HomepageMargins>
                <br />
                <br className="is-hidden-mobile" />
                <GuidesIndex />
              </HomepageMargins>
            </TileChild>
            <TileChild
              classes={[
                "is-flex",
                "is-flex-direction-column",
                "is-justify-content-flex-end",
              ]}
            >
              <HomepageMargins>
                <br />
                <h1 className="title is-5">Be notified of new 1-pagers</h1>
                <Signup shortCta={true} />
              </HomepageMargins>
            </TileChild>
          </TileParent>
          <TileParent>
            <TileChild>
              <HomepageMargins>
                <br />
                <br className="is-hidden-mobile" />
                <h1 className="title is-4">Consulting</h1>
                <p className="is-size-5">
                  I’ve worked for companies big and small, and have a good
                  knowledge of tools & technologies.
                </p>
                <br />
                <p className="is-size-5">
                  Let me know your business challenge. I can help you identify
                  the few actions that will have the biggest impact.
                </p>
              </HomepageMargins>
            </TileChild>
          </TileParent>
        </TileAncestor>
      </div>
      <Section>
        <TileAncestor>
          <TileParent>
            <TileChild>
              <br />
              <br />
            </TileChild>
          </TileParent>
        </TileAncestor>
      </Section>
    </Layout>
  )
}
export default Index

export const query = graphql`
  query {
    derek_hill_profile: file(
      relativePath: { eq: "index/derek_hill_profile.jpg" }
    ) {
      ...unsizedFluidImage
    }
  }
`
