import React from "react"
import validateClass from "../helpers/validate-class"

export const VALID_COLUMN_CLASSES = [
  "is-1",
  "is-2",
  "is-3",
  "is-4",
  "is-5",
  "is-6",
  "is-10",
  "has-text-centered",
  "is-half",
  "is-two-thirds",
  "is-three-quarters",
  "is-hidden-mobile",
  "is-hidden-tablet-only",
  "is-hidden-desktop-only",
  "px-5",
]

class Column extends React.Component {
  render() {
    const classes = this.props.classes || []

    validateClass(VALID_COLUMN_CLASSES, classes)

    return (
      <div className={`column ${classes.join(" ")}`} style={this.props.style}>
        {this.props.children}
      </div>
    )
  }
}

export default Column
