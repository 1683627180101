import React from "react"
import validateClass from "../helpers/validate-class"

class Columns extends React.Component {
  render() {
    const classes = this.props.classes || []

    const knownClasses = ["is-centered", "is-vcentered", "is-mobile", "is-flex"]

    validateClass(knownClasses, classes)

    return (
      <div className={`columns ${classes.join(" ")}`} style={this.props.style}>
        {this.props.children}
      </div>
    )
  }
}

export default Columns
